import React from "react"
import { Link } from "gatsby-plugin-react-i18next"
import { getPath } from "../utils/navigation"

export default function NavigationMenu({ pages, i18n, handleMenuClick, t }) {
  return (
    <nav className="flex justify-center">
      <ul className="flex space-x-4 text-xs uppercase">
        {pages.map(item => {
          const path = getPath(i18n.language, item)
          return (
            <li key={item}>
              <Link
                to={path}
                className="hover:text-gray-300 transition duration-300"
                activeClassName="font-bold"
                onClick={e => {
                  e.preventDefault()
                  handleMenuClick(path)
                }}
              >
                {t(`menu.${item}`)}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
