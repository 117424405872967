// utils/navigation.js

export const getPath = (language, item) => {
  return language === "en" ? `/${item}` : `/${language}/${item}`
}

export const getCurrentIndex = (location, pages, language) => {
  const currentPath = location.pathname
    .replace(`/${language}/`, "")
    .replace(`/`, "")
    .replace("/", "")
  return pages.indexOf(currentPath)
}
