module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#3A3938","display":"minimal-ui","icon":"src/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4f9641404c37e5a0f4bae4755f2d66f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","fr","es","it","jp"],"defaultLanguage":"en","fallbackLanguage":"en","siteUrl":"https://arcanode.io","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Ubuntu","file":"https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | Arcanode","defaultTitle":"Arcanode - Secure, High-Performing Solutions","description":"Arcanode is expertise and innovation for retail, wholesalers, and IT companies; it’s the secure and high-performing solution that empowers businesses without compromising on cost and quality.","canonical":"https://arcanode.io","openGraph":{"type":"website","locale":"en_US","url":"https://arcanode.io","title":"Arcanode - Secure, High-Performing Solutions","description":"Expertise and innovation for retail, wholesalers, and IT companies; secure and high-performing solutions without compromising on cost and quality.","images":[{"url":"https://arcanode.io/icons/icon-512x512.png","width":512,"height":512,"alt":"Arcanode Web Solutions"}],"site_name":"Arcanode"},"twitter":{"handle":"@arcanode","site":"@arcanode","cardType":"summary_large_image"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
